import React, { FC } from 'react'
import icons from 'components/theme/icons'
import { routes } from 'setup/constants'
import { PrimaryButton, TransparentButton, SecondaryButton } from 'components/common/common.styled'
import { Icon } from 'components/styled'
import { IButton } from 'utils/interface'
import { CustomLink } from 'components/button/CustomLink'
import { Button } from 'components/button/custom/styled'
import { Hero } from 'components/hero/Hero'
import { SloganStyled } from 'components/typography/view/index/styled'

interface IIndexHeroProps {
  user: boolean;
  onClick: () => void;
  labels: any;
}

export const IndexHero: FC<IIndexHeroProps> = ({
  user,
  onClick,
  labels,
  ...heroProps
}) => {

  return (
    <Hero {...heroProps}>
      <SloganStyled variant='dark' main={labels.sloganMain} p={[ labels.slogan[0] ]} />
      { user
        ?<DashboardButton to={routes.userDashboard}>{ labels.button.dashboard }</DashboardButton>
        :<>
          <RegisterButton onClick={onClick}>{ labels.button.register }</RegisterButton>
          <SloganStyled variant='dark' p={[ labels.slogan[1] ]} />
        </>
      }
    </Hero>
  )
}

export const RegisterButton: FC<IButton> = ({
  onClick,
  children
}) => (
  <Button item={PrimaryButton} iconRight={icons.arrowRight} onClick={onClick}>
    {children}
  </Button>
)

export const WatchButton: FC<IButton> = ({
  to,
  children
}) => (
  <CustomLink to={to}>
    <TransparentButton>{children}</TransparentButton>
  </CustomLink>
)

export const DashboardButton: FC<IButton> = ({
  to,
  children
}) => (
  <CustomLink to={to}>
    <SecondaryButton startIcon={<Icon icon={icons.userDashboard} />}>{children}</SecondaryButton>
  </CustomLink>
)