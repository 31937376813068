import React, { FC } from 'react'
import { Control, Controller } from 'react-hook-form'

interface IProps {
  fieldProps: any;
  name: string;
  label: string;
  control: Control;
  defaultValue?: string | number | boolean;
  className?: string;
  item: React.FC<any>;
}

export const FormControl: FC<IProps> = ({
  fieldProps,
  label,
  name,
  control,
  defaultValue,
  className,
  item: Item
}) => {
  return (
    <Controller
      render={({ field }) => <Item className={className} label={label} {...fieldProps} {...field} />}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  )
}
