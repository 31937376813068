import React, { FC } from 'react'
import { IChildren } from 'utils/interface'
import { GridStyled, HeroWrapper } from './styled'
import { Container, Grid } from 'components/core'

export enum HeroColor {
  primary = 'primary',
  rose = 'rose',
  info = 'info',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  black = 'black',
  gray = 'gray',
}

export type HeroIntensity =
  | ''
  | 'soft'
  | 'strong'

export interface IHeroProps extends IChildren {
  color?: HeroColor;
  intensity?: HeroIntensity;
  image?: string;
  className?: string;
}

export const Hero: FC<IHeroProps> = ({
  color,
  intensity,
  image,
  className,
  children
}) => (
  <HeroWrapper className={className} color={color} intensity={intensity} image={image}>
    <GridStyled container spacing={4}>
      <Grid item sm={12}>{children}</Grid>
    </GridStyled>
  </HeroWrapper>
)

/*
      <GridContainer justify="center" className={classes.container}>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mlAuto,
            classes.mrAuto,
            classes.textCenter,
            classes.raised
          )}
        >
          {children}
        </GridItem>
      </GridContainer>
   

Hero.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.oneOf([
    "primary",
    "rose",
    "dark",
    "info",
    "success",
    "warning",
    "danger"
  ]),
  intensity: PropTypes.oneOf([
    "soft",
  ]),
  children: PropTypes.node,
  style: PropTypes.string,
  image: PropTypes.string,
  small: PropTypes.bool
}


*/
