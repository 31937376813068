import { yupResolver } from "@hookform/resolvers/yup";
import { IInputProps } from "components/form/InputField";
import { useState } from "react";
import { useForm as hookForm, UseFormRegisterReturn } from "react-hook-form";
import * as yup from "yup";
import { setLocale } from 'yup';
import { es } from 'yup-locales';

setLocale(es)

export const FIELDS = {
  email: 'acabaEmail',
  password: 'acabaPassword',
  passwordConfirm: 'acabaPassword2',
  username: 'acabaUsername'
}

const errorLabels = {
  acabaEmail: 'La dirección email',
  acabaPassword: 'La contraseña',
  acabaPassword2: 'La contraseña',
  acabaUsername: 'El nombre de usuario'
}

const schemas = {
  acabaEmail: yup.string().email().required(),
  acabaPassword: yup.string().min(2).max(32).required(),
  acabaPassword2: yup.string().oneOf([yup.ref(FIELDS.password), null], 'Las contraseñas deben coincidir'),
  acabaUsername: yup.string().required()
}

const getSchema = (arr: string[]) => {
  return yup.object().shape(
    arr.reduce((tot, key) => {
      tot[key] = schemas[key]
      return tot
    }, {})
  )
}

const getProps = (arr: string[], register: (name: string) => UseFormRegisterReturn): Omit<IInputProps, 'label'> => {
  const def: IInputProps = <IInputProps> {}
  return arr.reduce((tot, key) => {
    tot[key] = register(key)
    tot[key].name = key
    return tot
  }, def)
}

const getDefaultValues = (arr: string[])  => {
  return arr.reduce((tot, key) => {
    tot[key] = ''
    return tot
  }, {})
}
export default function useForm(fields: string[], onSubmitFn: any) {

  const schema = getSchema(fields)
  const [ disabled, setDisabled ] = useState(false)
  const { control, register, handleSubmit, reset, formState } = hookForm({
    mode: 'all',
    defaultValues: getDefaultValues(fields),
    resolver: yupResolver(schema),
  });

  const { isValid, errors = [] } = formState
  
  let fieldProps: Omit<IInputProps, 'label'> = <IInputProps> null
  fieldProps = getProps(fields, register)
  const onSubmit = handleSubmit((data: any) => {
    try {
      onSubmitFn(data)
      setDisabled(true)
      setTimeout(() => {
        setDisabled(false)
        reset()
      }, 2000)
    } catch (err) {
      setDisabled(false)
    }
  })

  return {
    control,
    onSubmit,
    disabled: !isValid || disabled,
    fieldProps,
    errorProps: {
      errors,
      labels: errorLabels
    }
  }
};
