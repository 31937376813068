import React, { FC } from 'react';
import { TransText } from 'components/common/TransText'

interface IProps {
  className?: string;
  main?: string;
  p?: any[];
  variant?: string;
}

export const Slogan: FC<IProps> = ({
  className,
  variant,
  main,
  p = [],
}) => {
  return (
    <div className={`${className} ${variant}`}>
      <h1>{ main }</h1>
      { p.map((text, i) => <p key={i}><TransText text={text} /></p>)}
    </div>
  )
}
