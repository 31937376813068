import React, { FC } from 'react';
import { ErrorSpaceStyled, ErrorStyled } from './styled';


interface IProps {
  errors?: any;
  labels?: any;
}


export const Errors: FC<IProps> = ({
  errors = {},
  labels = {}
}) => {

  const keys = Object.keys(errors).slice(0,1)
  const message = (key: string) => {
    const str = errors[key].message
    const label = labels[key]
    
    return <i key={key}>{str.replace(key, label)}</i>
  }

  return (
    <>{ !!keys.length && 
      <ErrorSpaceStyled>
        <ErrorStyled>{keys.map(message)}</ErrorStyled>
      </ErrorSpaceStyled>}
    </>
  )
}