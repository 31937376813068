import styled from 'styled-components'
import { FormControlLabel, TextField } from 'components/core'
import { Icon } from 'components/styled'
import { grayColor, whiteColor } from 'components/theme/constants'

export const FormControlLabelStyled = styled(FormControlLabel)`
  width: 100%;
  & label {
    color: ${grayColor[4]};
  }
  [class^="MuiTypography-root"] {
    flex: 1;
  }
`

export const UncheckedIconStyled = styled(Icon)`
  color: ${grayColor[4]};
`
export const TextFieldStyled = styled(TextField).attrs(props => ({
  variant: 'outlined',
  size: 'small'
}))`
`
export const Textarea = styled(TextField).attrs(props => ({
  multiline: true,
  variant: 'outlined',
  rows: 3
}))`
  margin: 0;
  & textarea {
    padding: 0;
    margin: 0;
    font-size: 0.7rem;
    color: ${grayColor[0]};
  }
  .Mui-focused {
    background: ${whiteColor};
  }
  .Mui-focused textarea {
    color: ${grayColor[5]};
  }

`