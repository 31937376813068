import
 React, {
 FC,
} from 'react';
import { IRecoverProps } from 'utils/interface';

import {
 EmailField,
 Form,
 LogoStyled,
 SubTitle,
 Title
} from './styled';
import { SubmitButton } from './SubmitButton';
import { Errors } from './Errors';
import useForm, { FIELDS } from 'use/form/useForm';

interface IProps {
  handleRecover?: (props: IRecoverProps) => void;
}

export const FormRecover: FC<IProps> = ({
  handleRecover,
}) => {

  const { onSubmit, fieldProps, errorProps, disabled } = useForm([
    FIELDS.email
  ], (data) => {
    handleRecover({
      email: data[FIELDS.email]
    })
  })

  const labels = {
    button: 'Enviar',
    email: 'Email',
    password: 'Contraseña',
    recover: '¿Has olvidado la contraseña?',
    subtitle: 'Introduce la dirección email con la que te has registrado.',
    subtitle2: ' Te enviaremos un correo con un enlace para restablecer la contraseña',
    switch: 'Empieza aquí',
    title: 'Restablecer contraseña'
  }

  return (
    <>
      <Title><LogoStyled />{ labels.title }</Title>
      <SubTitle>{ labels.recover }</SubTitle>
      <SubTitle>{ labels.subtitle }</SubTitle>
      <SubTitle>{ labels.subtitle2 }</SubTitle>
      <Form onSubmit={onSubmit}>
        <EmailField
          {...fieldProps[FIELDS.email]}
          label={labels.email}
        />
        <Errors { ...errorProps} />
        <SubmitButton
          disabled={disabled}
          label={labels.button}
          />
     </Form>
   </>
  )
}

/*
        <SignupSet>
          <TextStyled>{ labels.help }<CustomLink to={routes.help}>{ labels.helpLink }</CustomLink></TextStyled>
        </SignupSet>
        */