import
 React, {
 FC,
} from 'react';
import { IResetProps } from 'utils/interface';

import {
 Form,
 LogoStyled,
 SubTitle,
 Title
} from './styled';
import { SubmitButton } from './SubmitButton';
import { Errors } from './Errors';
import useForm, { FIELDS } from 'use/form/useForm';
import { PasswordField } from './PasswordField';

interface IProps {
  handleReset?: (props: IResetProps) => void;
}

export const FormReset: FC<IProps> = ({
  handleReset,
}) => {

  const { onSubmit, fieldProps, errorProps, disabled } = useForm([
    FIELDS.password,
    FIELDS.passwordConfirm,
  ], (data) => {
    handleReset({
      password: data[FIELDS.password],
    })
  })

  const labels = {
    button: 'Actualizar',
    password: 'Contraseña',
    password2: 'Confirmar contraseña',
    subtitle: 'Introduce una contraseña para sustituir a la actual',
    title: 'Nueva contraseña'
  }


  return (
    <>
      <Title><LogoStyled />{ labels.title }</Title>
      <SubTitle>{ labels.subtitle }</SubTitle>
      <Form onSubmit={onSubmit}>
        <PasswordField
          {...fieldProps[FIELDS.password]}
          label={labels.password}
        />
        <PasswordField
          {...fieldProps[FIELDS.passwordConfirm]}
          label={labels.password2}
        />
        <Errors { ...errorProps} />
        <SubmitButton
          disabled={disabled}
          label={labels.button}
          />
     </Form>
   </>
  )
}