import React, { FC } from 'react';

import { SignupSet, SwitchButton, TextStyled } from './styled';

interface IProps {
  label: string;
  button: string | React.ReactNode;
  onClick: () => void;
}

export const SwitchView: FC<IProps> = ({
  label,
  button,
  onClick,
}) => (
  <SignupSet>
    <TextStyled>{ label }</TextStyled>
    <SwitchButton onClick={onClick} >{ button }</SwitchButton>
  </SignupSet>
)
