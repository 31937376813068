import React, {
  FC,
  MouseEvent,
  useState,
  useEffect
 } from 'react'
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { InputAdornment, IconButton } from 'components/core'



interface IPasswordProps {
  label: string;
  name: string;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  className?: string;
 }
 
export const PasswordField: FC<IPasswordProps> = ({
  label,
  name,
  onBlur,
  onChange,
  onKeyDown,
  className
}) => {
   const [showPassword, setShowPassword] = useState(false)
   const [readonly, setReadonly] = useState(true)

   const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => {
     e.preventDefault();
   }

   useEffect(() => {
    setTimeout(() => {
      setReadonly(false)
    }, 200)
   }, [])
   return (
    <FormControl
      variant="outlined"
      size="small"
      >
      <InputLabel htmlFor={name}>{ label }</InputLabel>
      <OutlinedInput
        inputProps={{
          readonly: readonly && 'readOnly'
        }}
        className={className}
        type={showPassword ? 'text' : 'password'}
        labelWidth={label.length * 6.5}
        name={name}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <Visibility fontSize="small" />
              ) : (
                <VisibilityOff fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  )
 }