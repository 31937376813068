import styled from 'styled-components'
import images from 'components/theme/images'
import { IndexHero } from 'compose/hero/IndexHero'

export const IndexHeroStyled = styled(IndexHero).attrs(() => ({
  color: 'black',
  intensity: 'strong',
  image: images.mainBackground
}))`
  margin: 2rem 0;
  text-align: center;
  & a {
    margin: 0.5rem;
  }
`
