import styled from 'styled-components'
import { whiteColor } from 'components/theme/constants'
import { Slogan } from 'components/typography/view/index/Slogan'

export const SloganStyled = styled(Slogan)`
  text-align: center;
  font-size: 0.9rem;
  & h1 {
    font-size: 2rem;
  }
  &.dark {
    color: ${whiteColor};
  }
`

