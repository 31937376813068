import styled from 'styled-components'

import { Button, Typography } from 'components/core'
import { dangerColor, grayColor } from 'components/theme/constants'
import { ButtonClearStyled } from 'components/button/styled'
import { Logo } from 'components/logo/Logo'
import { Icon } from 'components/styled'
import { FormControl } from 'components/form/FormControl'
import { InputField } from 'components/form/InputField'
import { TextFieldStyled } from 'components/form/styled'


export const ButtonStyled = styled(Button).attrs(({
  variant: 'contained',
  color: 'primary'
}))`
  margin-top: 1rem;
  width: 100%;
`


export const EmailField = styled(TextFieldStyled).attrs({
})`
`

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 1px;
  & input, & label {
    font-size: 0.8rem;
  }
`
export const FormControlStyled = styled(FormControl)`
  margin-bottom: 0.5rem;
`
export const IconStyled = styled(Icon)`
  margin-right: 1rem;
`
export const LogoStyled = styled(Logo)`
  & button {
    height: 1rem;
    margin-left: -0.5rem;
  }
`
export const NameField = styled(InputField).attrs({
})`
  margin: 1rem 0;
`
export const RecoverButton = styled(ButtonClearStyled)`
  font-size: 0.7rem;
  text-decoration: underline;
  text-align: right;
`
export const TextStyled = styled(Typography).attrs(({
  variant: 'caption'
}))`
  line-height: 0.9rem;
  display: block;
`

export const Title = styled.h2<{ theme }>`
  margin-top: 0;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 1.2rem;
  line-height: 1.2rem;
  border-bottom: 1px solid ${grayColor[0]};
  text-align: center;
  display: grid;
  grid-template-columns: 0 1fr;

  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints.values.xs}px) {
    font-size: 0.8rem;
    padding-bottom: 0.4rem;
  }
`


export const SignupSet = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${grayColor[0]};
  padding-top: 1rem;
`

export const SubTitle = styled(TextStyled)`
  margin-bottom: 1rem;
  text-align: center;
`
export const SubTitle2 = styled(SubTitle)`
  color: ${grayColor[12]};
  font-style: italic;
`
export const SwitchButton = styled(Button).attrs(({
  type: 'button',
  variant: 'outlined'
}))`
  width: 100%;
  margin-top: 0.5rem;
`
export const ErrorStyled = styled.div`
  color: ${dangerColor[2]};
  margin: 0 0 1rem;
  border-radius: 8px;
  font-style: italic;
  font-weight: bold;
  font-size: 0.7rem;
  flex-direction: column;
  height: 3rem;
  width: 100%;
`
export const ErrorSpaceStyled = styled.div`
  height: 3rem;
  display: block;
`