import React, { FC, useState, memo } from 'react'

import { navigate } from 'gatsby'

import { AUTH_TAB, PopupAuth } from 'components/popup/auth/PopupAuth'
import { Modal } from 'components/modal/Modal'
import useAuth from 'use/auth/useAuth'
import { routes } from 'setup/constants'
import { useDispatch } from 'react-redux'
import useI18n from 'use/i18n/useI18n'
import { IRegisterProps, ILoginProps, IRecoverProps, IResetProps } from 'utils/interface'
import { IAuthModel } from 'model/Auth.model'
import { useApolloClient } from '@apollo/client'
import { useModalAlert, useModalAuth } from 'use/modal/useModal'


interface IProps {
  defaultTab?: number;
}
const PopupAuthComposeMemo: FC<IProps> = ({ defaultTab = AUTH_TAB.LOGIN }) => {
  const { open: openAlert, clear } = useModalAlert()
  const [tab, setTab] = useState(defaultTab)
  const dispatch = useDispatch()
  const { t } = useI18n()
  const { login, register, recoverPassword, resetPassword } = useAuth()
  const { isOpen, open, close } = useModalAuth()
  
  const client = useApolloClient()
  const clearCache = () => {
    client.clearStore()
  }
  const onError = (str: any) => {
    openAlert({
      errorMessage: t(str),
      severity: 'error'
    })
  }
  const onSuccess = (str: any) => {
    openAlert({
      errorMessage: str,
      severity: 'success'
    })
  }
  const handleClose = () => {
    close();
    setTab(AUTH_TAB.LOGIN)
  }

  const handleSignup = (signupData: IRegisterProps) => {
    clearCache()
    register({ ...signupData, ...{
      dispatch,
      onSuccess: (response: IAuthModel) => {
        clear()
        navigate(routes.userDashboard)
      },
      onError
    }})
  };

  const handleLogin = (loginData: ILoginProps) => {
    clearCache()
    login({ ...loginData, ...{
      dispatch,
      onSuccess: (response: IAuthModel) => {
        clear()
        navigate(routes.userDashboard)
      },
      onError
    }})
  };

  async function handleRecover (recoverData: IRecoverProps) {
    const response = await recoverPassword({ ...recoverData, ...{
      dispatch,
      onSuccess: (response: IAuthModel) => {
        onSuccess(t(response.data.message, { email: recoverData.email })),
        handleClose()
      },
      onError
    }})

    if (!response.success) {
      setTab(AUTH_TAB.RECOVER)
    }
  }

  async function handleReset (data: IResetProps) {
    const response = await resetPassword({ ...data, ...{
      dispatch,
      onSuccess: (response: IAuthModel) => {
        onSuccess(t(response.data.message)),
        handleClose()
      },
      onError: (err) => {
        handleClose()
        onError(err)
        setTimeout(() => {
          setTab(AUTH_TAB.RECOVER)
          open()
        }, 2000)
      }
    }})

    if (!response.success) {
      setTab(AUTH_TAB.RESET)
    }
  }

  return isOpen ?
    <Modal open={isOpen} handleClose={handleClose}>
      <PopupAuth
        tab={tab}
        handleLogin={handleLogin}
        handleSignup={handleSignup}
        handleRecover={handleRecover}
        handleReset={handleReset}
        handleClose={handleClose}
      />
    </Modal>
   : <></>
}

export const PopupAuthCompose = memo(PopupAuthComposeMemo);
