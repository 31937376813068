import
 React, {
 FC,
} from 'react';


import { ILoginProps } from 'utils/interface';

import {
 EmailField,
 Form,
 FormControlStyled,
 LogoStyled,
 RecoverButton,
 SubTitle,
 Title
} from './styled';
import { PasswordField } from './PasswordField';
import { SwitchView } from './SwitchView';
import { Errors } from './Errors';
import { SubmitButton } from './SubmitButton';
import useForm, { FIELDS } from 'use/form/useForm';

interface IProps {
 handleClose?: () => void;
 setTabName: (tabIndex: number) => void;
 handleLogin?: (props: ILoginProps) => void;
}

export const FormLogin: FC<IProps> = ({
 handleClose,
 handleLogin,
 setTabName,
}) => {

  
  const { control, onSubmit, fieldProps, errorProps, disabled } = useForm([FIELDS.email, FIELDS.password], (data) => {
    handleLogin({
      email: data[FIELDS.email],
      password: data[FIELDS.password]
    })
  })

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onSubmit()
    }
  }
  const labels = {
    button: 'Acceso',
    email: 'Email',
    notyet: '¿No tienes cuenta aún?',
    password: 'Contraseña',
    recover: '¿Has olvidado la contraseña?',
    subtitle: 'Para continuar, inicia sesión en Acabáramos.',
    switch: 'Empieza aquí',
    title: 'Iniciar sesión'
  }

  return (
    <>
      <Title><LogoStyled />{ labels.title }</Title>
      <SubTitle>{ labels.subtitle }</SubTitle>
      { fieldProps && 
      <Form onSubmit={onSubmit}>
        <FormControlStyled
          fieldProps={fieldProps[FIELDS.email]}
          label={labels.email}
          name={FIELDS.email}
          control={control}
          item={EmailField} />
        <FormControlStyled
          fieldProps={{...fieldProps[FIELDS.password], ...{ onKeyDown }}}
          label={labels.password}
          name={FIELDS.password}
          control={control}
          item={PasswordField} />
        <RecoverButton
          onClick={() => setTabName(2)}>{labels.recover}
        </RecoverButton>
        <Errors { ...errorProps} />
        <SubmitButton
          disabled={disabled}
          label={labels.button}
          />
        <SwitchView
          label={labels.notyet}
          button={labels.switch}
          onClick={() => setTabName(1)}
        />
        
     </Form>}
   </>
 );
}

