import React, { FC, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { ILoginProps, IRecoverProps, IRegisterProps, IResetProps } from 'utils/interface';
import { FormLogin } from './FormLogin';
import { FormRecover } from './FormRecover';
import { FormReset } from './FormReset';
import { FormSignup } from './FormSignup';

export enum AUTH_TAB {
  LOGIN = 0,
  SIGNUP = 1,
  RECOVER = 2,
  RESET = 3
}
interface IProps {
  tab: AUTH_TAB;
  handleClose: () => void;
  handleLogin: (props: ILoginProps) => void;
  handleSignup: (props: IRegisterProps) => void;
  handleRecover: (props: IRecoverProps) => void;
  handleReset: (props: IResetProps) => void;
}

export const PopupAuth: FC<IProps> = ({
  tab,
  handleClose,
  handleSignup,
  handleRecover,
  handleReset,
  handleLogin,
}) => {
  const [tabName, setTabName] = useState(tab);
  const tabSwitcher = [
    <FormLogin
      handleClose={handleClose}
      handleLogin={handleLogin}
      setTabName={setTabName}
    />
    ,
    <FormSignup
      handleClose={handleClose}
      handleSignup={handleSignup}
      setTabName={setTabName}
    />
    ,
    <FormRecover
      handleRecover={handleRecover}
    />
    ,
    <FormReset
      handleReset={handleReset}
    />
  ]

  return tabSwitcher[tabName] || <></>
}
