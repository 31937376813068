import styled, { css } from 'styled-components';
import { layoutWrap } from 'components/layout/styled';
import { Grid } from 'components/core';
import { blackColor, dangerColor, grayColor, hexToRgb, infoColor, primaryColor, roseColor, successColor, warningColor } from 'components/theme/constants';

const colors = {
  primary: { a: primaryColor[4], b: primaryColor[5] },
  rose: { a: roseColor[3], b: roseColor[4] },
  info: { a: infoColor[6], b: infoColor[7] },
  success: { a: successColor[6], b: successColor[7] },
  warning: { a: warningColor[6], b: warningColor[7] },
  danger: { a: dangerColor[6], b: dangerColor[7] },
  black: { a: blackColor, b: grayColor[3] },
}
const colouredBackground = (color = 'primary', intensity = 'default') => {
  const { a, b } = colors[color]
  const { ia, ib } = {
    strong: { ia: '.56', ib: '.65' },
    default: { ia: '.36', ib: '.45' },
    soft: { ia: '.16', ib: '.25' }
  }[intensity]
  const bgA = `rgba(${hexToRgb(a)},${ia})`
  const bgB = `rgba(${hexToRgb(b)},${ib})`
  return css` 
    &:before {
      background: rgba(${hexToRgb(blackColor)}, 0.5);
    }
    &:after {
      background: linear-gradient(60deg,${bgA},${bgB});
    }
    &:after,&:before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      content: '';
    }
  `
}

export const HeroWrapper = styled.article`
  position: relative;
  display: block;
  
  background-size: cover;
  background-position: center;
  padding: 2rem;
  text-align: center;
  ${({ color, intensity }) => colouredBackground(color, intensity)}
  ${({ image }) => image ? css`
    background-image: url(${image});
  ` : ''}
`

export const GridStyled = styled(Grid)`
  z-index: 2;
  position: relative;
`