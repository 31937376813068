import
  React, {
  FC,
} from 'react';

import { IRegisterProps } from 'utils/interface';

import {
  EmailField,
  Form,
  FormControlStyled,
  IconStyled,
  LogoStyled,
  NameField,
  SubTitle,
  SubTitle2,
  Title,
} from './styled';
import { PasswordField } from './PasswordField';
import { SwitchView } from './SwitchView';
import icons from 'components/theme/icons';

import { Errors } from './Errors';
import { SubmitButton } from './SubmitButton';
import useForm, { FIELDS } from 'use/form/useForm';

interface IProps {
  handleClose?: () => void;
  setTabName: (tabIndex: number) => void;
  handleSignup?: (props: IRegisterProps) => void;
}
export const FormSignup: FC<IProps> = ({
  handleClose,
  handleSignup,
  setTabName,
}) => {

  const { control, onSubmit, fieldProps, errorProps, disabled } = useForm([
    FIELDS.email,
    FIELDS.password,
    FIELDS.passwordConfirm,
    FIELDS.username
  ], (data) => {
    handleSignup({
      email: data[FIELDS.email],
      password: data[FIELDS.password],
      username: data[FIELDS.username]
    })
  })

  const labels = {
    button: 'Registrarme gratis',
    email: 'Email',
    notyet: 'Ya me he registrado',
    password: 'Contraseña',
    password2: 'Confirmar contraseña',
    subtitle: 'Por favor, introduce tus datos para poder identificarte.',
    subtitle2: 'Los campos son obligatorios (podrán cambiarse después).',
    switch: 'Inicio',
    title: 'Registro',
    username: 'Elige un nombre de usuario'
  }

  return (
    <>
      <Title><LogoStyled />{ labels.title }</Title>
      <SubTitle>{ labels.subtitle }</SubTitle>
      <SubTitle2>{ labels.subtitle2 }</SubTitle2>
      <Form onSubmit={onSubmit} autoComplete='new-password'>
        <FormControlStyled
          fieldProps={fieldProps[FIELDS.email]}
          label={labels.email}
          name={FIELDS.email}
          control={control}
          item={EmailField} />
        <FormControlStyled
          fieldProps={fieldProps[FIELDS.password]}
          label={labels.password}
          name={FIELDS.password}
          control={control}
          item={PasswordField} />
        <FormControlStyled
          fieldProps={fieldProps[FIELDS.passwordConfirm]}
          label={labels.password2}
          name={FIELDS.passwordConfirm}
          control={control}
          item={PasswordField} />
        <FormControlStyled
          fieldProps={fieldProps[FIELDS.username]}
          label={labels.username}
          name={FIELDS.username}
          control={control}
          item={NameField} />
        <Errors { ...errorProps} />
        <SubmitButton
          disabled={disabled}
          label={labels.button}
          />
        <SwitchView
          label={labels.notyet}
          button={<><IconStyled icon={icons.userDashboard}/>{labels.switch}</>}
          onClick={() => setTabName(0)}
        />
      </Form>
    </>
  );
}
