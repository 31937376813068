import React, {
  FC,
 } from 'react'
import { TextFieldStyled } from './styled';


export interface IInputProps {
  label: string;
  name: string;
  value?: string;
  ref?: React.Ref<any>;
  onBlur?: (e: any) => void;
  onChange: (e: any) => void;
  disabled?: boolean;
  className?: string;
 }
 
export const InputField: FC<IInputProps> = ({
  label,
  name,
  value,
  disabled,
  onBlur,
  onChange,
  className
}) => {

  return (
    <TextFieldStyled
      className={className}
      label={label}
      name={name}
      disabled={disabled}
      defaultValue={value}
      inputProps={{
        type: 'search',
        autoComplete: 'new-password',
        onChange,
        onBlur
      }}
    />
  )
 }