import React, { FC } from 'react'
import useI18n from 'use/i18n/useI18n'
import { useModalAuth } from 'use/modal/useModal'
import useTag from 'use/tag/useTag'
import useAuth from 'use/auth/useAuth'
import { IndexHeroStyled } from 'compose/hero/styled'

const slogan = [
  'pages.home.slogan.0',
  'pages.home.slogan.1',
  'pages.home.slogan.2'
]

const labelIds = {
  sloganMain: 'pages.home.slogan.main',
  buttonDashboard: 'pages.home.button.dashboard',
  buttonRegister: 'pages.home.button.register',
  buttonWatch: 'pages.home.button.watch'
}
interface IProps {
  className?: string;
  page: string;
}

export const IndexHeroCompose: FC<IProps> = ({
  className,
  page
}) => {
  const labels = useI18n().tm(labelIds)
  const { open: openAuth } = useModalAuth()
  const user  = useAuth().user
  const { tag } = useTag()

  const onClick = () => {
    openAuth()
    tag({ event: 'indexHeroButtonClicked', value: page })
  }

  return (
    <IndexHeroStyled
      className={className}
      user={!!user}
      onClick={onClick}
      labels={{
        main: labels.sloganMain,
        slogan,
        button: {
          dashboard: labels.buttonDashboard,
          register: labels.buttonRegister,
          watch: labels.buttonWatch
        } 
      }} />
  )
}
