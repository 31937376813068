import React, { FC } from 'react';

import { ButtonStyled } from './styled';

interface IProps {
  label: string;
  disabled?: boolean;
}

export const SubmitButton: FC<IProps> = ({
  label,
  disabled,
}) => {

  return (
    <ButtonStyled
      type='submit'
      variant={ disabled ? 'outlined' : 'contained'}
      disabled={ disabled }
    >{ label }
    </ButtonStyled>
  )
}